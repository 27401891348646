
import MitDropdownMenu from '../ui-library/MitDropdownMenu.vue'
import $ from 'jquery'

export default {
    components: {
        'mit-dropdown-menu': MitDropdownMenu
    },
    data() {
        return {
            headerNav: [
                { name: 'Home', path: '/' },
                { name: 'Business Partners', path: '/business' },
                { name: "Creator's World", path: '/creator' },
                { name: 'Developers', path: '/developers' },
                { name: 'FAQs', path: '/faqs' },
                { name: 'Contact Us', path: '/contact' }
            ]
        }
    },
    methods: {
        navigate() {
            this.$router.push({ path: '/login' })
        },
        logout() {
            this.$store.commit('logout')
            this.$router.push({ path: '/' })
        },
        handleCommand(command: string | number | object) {
            switch (command) {
                case 'Log out':
                    this.logout()
                    this.closeNav()
                    break
                default:
                    this.$router.push({ name: command })
                    this.closeNav()
                    break
            }
        },

        closeNav() {
            ;($('#navbar') as any).collapse('hide')
        }
    },
    computed: {
        isLogin() {
            return this.$store?.state?.user?.isLogin
        },
        menuList() {
            if (process.env.NODE_ENV === 'cn-development') {
                return [
                    'My Projects',
                    'My Project Income',
                    'Edit Profile',
                    'Log out'
                ]
            }
            return [
                'My Projects',
                'My Project Income',
                'My Purchase History',
                'Edit Profile',
                'Log out'
            ]
        }
    },
    created() {
        addEventListener('click', (event) => {
            if (
                (event.target as any).className.indexOf('router-link-active') <
                    0 &&
                (event.target as any).className.indexOf('nav-btn') < 0
            ) {
                this.closeNav()
            }
        })
    }
}

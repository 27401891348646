import { Loader, LoaderOptions } from "google-maps";

export enum ProductStatus {
  PrepareForSubmission = "Prepare For Submission",
  UnderReviewing = "Under Reviewing",
  AcceptedAndReleased = "Accepted And Released",
  Rejected = "Rejected",
  SubmitedWaitingReview = "Submited Waiting Review",
}

// export enum StatusColor {
//   Rejected = "#ff0033",
//   AcceptedAndReleased = "#00cc00",
//   PrepareForSubmission = "#3366ff"
// }

export enum StatusRowClass {
  Rejected = "error-row",
  AcceptedAndReleased = "success-row",
}

export enum ProductVersionStatus {
  Active = "Active",
  TakeDown = "TakeDown",
}

export enum StatusFormatted {
  Active = "Active",
  TakeDown = "Take Down",
}

export const VersionStateActionBtn = {
  Active: { btnName: "Take Down" },
  TakeDown: { btnName: "Active" },
};

export enum VersionType {
  historyVersions = "historyVersions",
  releaseVersion = "releaseVersion",
  testingVersion = "testingVersion",
}

export enum UploadFileType {
  IMAGE,
  MEDIA,
}

export enum SignalRGameAction {
  //Basic Game Controller
  StartGame = "StartGame",
  EndGame = "EndGame",
  CloseGame = "CloseGame",
  ViewGame = "ViewGame",
  Win = "Win",
  Lose = "Lose",
  // get info
  GetGameInformation = "GetGameInformation",
  // join/leave game
  JoinGame = "JoinGame",
  LeaveGame = "LeaveGame",
  LeaveAllGame = "LeaveAllGame",
  // create game/restart game
  RestartGame = "RestartGame",
  CreateGame = "CreateGame",
  SetAchieveGoalInRound = "SetAchieveGoalInRound",

  StartGameV1 = "StartGameV1",
  SetMyGameInfo = "SetMyGameInfo",
}

export enum TemplateType {
  MarketplaceAgreement = "MarketplaceAgreement",
  MarketplaceTermsofUse = "MmitaMarketplaceTermsOfUse",
  DeveloperAgreement = "DeveloperAgreement",
}

export enum IncomeStatus {
  Unsettled = "Preparing Commission",
  Settled = "Collected",
  NonSettlement = "Non Settlement",
  Settling = "Released Commission",
  SettledByStripePaymentSession = "Settled By Stripe Payment Session",
}

export const NonBanner = ["/", "/config-vps-placement"];
export const FullPage = ["/", "/config-vps-placement", "/vps-placement", '/creator'];

export const options: LoaderOptions = { libraries: ["geometry", "places"] };
export const loader = new Loader(
  "AIzaSyCDfjSu4kzFtYvXrP1Mwl1TLrWYve1xmqc",
  options
);

export enum ButtonType {
  HighLight = "highLight",
}

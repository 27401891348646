
import { Options, Vue } from 'vue-class-component'
import ContactUsForm from '@/components/view/ContactUsForm.vue'
import { CaretRight, CaretBottom } from '@element-plus/icons-vue'
import { MAIN_WEBSITE_URL } from '../../environment'

@Options({
    components: {
        ContactUsForm,
        CaretRight,
        CaretBottom
    },
    mounted() {
        window.scroll(0, 0)
    },
    computed: {
        isSubscribed() {
            return this.$store.state.user?.userProfile?.isSubscribed
        },
        pricePermonthToString() {
            return this.$store.state.global.setting.pricePermonthToString
        },
        isCN() {
            return process.env.NODE_ENV === 'cn-development'
        },
        isLogin() {
            return this.$store.state.user.isLogin
        },
        vpsAppOfMacOsFileUrl() {
            const vpsUrl = this.$store.state.global.setting.vpsAppOfMacOsFileUrl
            const reg = /(http|https):\/\/([\w.]+\/?)\S*/gi
            return vpsUrl?.match(reg) ? vpsUrl : null
        }
    }
})
export default class CreatorView extends Vue {
    creatorIntroduces = CreatorIntroduces
    steps = Steps
    openTab(ev) {
        const iosLink = 'https://apps.apple.com/hk/app/mmita/id1522417329'
        const androidLink =
            'https://play.google.com/store/apps/details?id=com.Bamb.MMITA'
        const InstagramLink = 'https://www.instagram.com/mmitaverse/'
        switch (ev) {
            case 'ios':
                window.open(iosLink)
                break
            case 'andriod':
                window.open(androidLink)
                break
            case 'MMITA':
                window.open(MAIN_WEBSITE_URL)
                break
            case 'Instagram':
                window.open(InstagramLink)
                break
        }
    }
    downloadFile(href) {
        const link = document.createElement('a')
        link.href = href
        link.download = 'MMITA VPS App'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
}

const Steps = [
    {
        url: require('@/assets/image/step1.png'),
        description: 'Firstly, find your placing location'
    },
    {
        url: require('@/assets/image/step2.png'),
        description:
            'And then, select and adjust the position of the objects on the 3D map'
    },
    {
        url: require('@/assets/image/step3.png'),
        description:
            'Finally, publish and check them out from AR camera of MMITA mobile app in the location'
    }
]
const CreatorIntroduces = [
    {
        iconName: 'image',
        color: '#5F5FFF',
        title: 'Images',
        des: `Adv poster, art pieces, your photos`
    },
    {
        iconName: 'video',
        color: '#F3BA2F',
        title: 'Videos',
        des: `Adv Video, movie Trailer, short movie`
    },
    {
        iconName: 'cube',
        color: '#00C3B7',
        title: '3D Assets',
        des: `Our preset 3D file or your approved 3D file`
    },
    {
        iconName: 'vr-cardboard',
        color: '#FF5851',
        title: 'Special Utilities',
        des: `Vertical scrolling image banner, google review banner`
    }
]
